import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallButton from "../components/callButton"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <section className=" py-16">
      <h1 className="text-2xl md:text-4xl font-semibold text-blue-400 pb-8">
        404: Page Not Found
      </h1>
      <p className="pb-8 text-gray-800">
        This page is broken, but your plumbing doesn't have to be!
      </p>
      <CallButton />
    </section>
  </Layout>
)

export default NotFoundPage
